"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const tinycolor_1 = require("@ctrl/tinycolor");
//@ts-ignore
const ntc_ts_1 = require("ntc-ts");
const action_1 = require("@src/app/services/action");
const utils_1 = require("@src/shared/utils");
const palette_from_image_1 = require("palette-from-image");
const directives_1 = require("@games/_base64/src/directives");
(0, ntc_ts_1.initColors)(ntc_ts_1.ORIGINAL_COLORS);
const defaultColors = 5;
class PaletteCtrl {
    constructor($scope, $timeout, actionService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.actionService = actionService;
        this.data = {
            color: this.randomColor(),
            imagePickerPaletteIndex: 0,
            image: undefined,
            canvasCirclePoint: ['0px', '0px'],
            paleteColors: defaultColors,
        };
        this.kind = 'palette';
        this.palette = [];
        this.history = [];
        this.historyIndex = 0;
        this.hasUndo = false;
        this.hasGen = true;
        this.canvas = document.getElementById('canvas_picker');
        this.ctx = this.canvas.getContext('2d');
        this.canvasCircle = document.getElementById('canvas_circle');
        this.canvasCirclePreview = document.getElementById('canvas_circle_preview');
        this.ctxPreview = this.canvasCirclePreview.getContext('2d');
        this.$palette = new rxjs_1.Subject();
        this.mouseDown$ = (0, rxjs_1.fromEvent)(this.canvasCircle, 'mousedown');
        this.mouseMove$ = (0, rxjs_1.fromEvent)(document, 'mousemove');
        this.mouseUp$ = (0, rxjs_1.fromEvent)(document, 'mouseup');
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
        this.$scope.$watch('$ctrl.data.imageDataUri', (imageDataUri) => {
            if (imageDataUri) {
                this.drawImage(imageDataUri);
            }
        });
        window.addEventListener('keydown', function (e) {
            if (e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
            }
        });
    }
    $onInit() {
        this.$palette.pipe((0, operators_1.startWith)((this.kind == 'palette') ? (new tinycolor_1.TinyColor(this.data.color)).polyad(defaultColors) : undefined), (0, operators_1.map)((value) => {
            if (value == undefined) {
                if (this.history.length > 0) {
                    return [this.history[this.historyIndex], true];
                }
                else {
                    return [[], false];
                }
            }
            else {
                return [value.map((color) => {
                        const pos = this.kind == 'image' ? this.getColorPos(color) : undefined;
                        return {
                            canvasCirclePoint: pos ? [`${pos[0]}px`, `${pos[1]}px`] : undefined,
                            color: color,
                            ntc: (0, ntc_ts_1.getColorName)(color.toString('hex')),
                            shades: []
                        };
                    }), false];
            }
        }), (0, operators_1.tap)((value) => {
            let hasChange = false;
            value[0].forEach((item, index) => {
                if (index < this.palette.length) {
                    if (value[1]) {
                        this.palette[index] = item;
                        hasChange = true;
                    }
                    else {
                        const showShades = this.palette[index].shades.length;
                        if (!this.palette[index].lock) {
                            this.palette[index] = item;
                            hasChange = true;
                        }
                        if (showShades) {
                            this.palette[index].shades = this.getShades(item.color);
                            hasChange = true;
                        }
                    }
                }
                else {
                    this.palette.push(item);
                    hasChange = true;
                }
            });
            if (hasChange) {
                this.addToHistory(ng.copy(value[0]), value[1]);
            }
        })).subscribe();
        (0, rxjs_1.merge)((0, rxjs_1.fromEvent)(this.canvas, 'mousedown'), this.mouseDown$).pipe(// всякий раз, когда мы нажимаем на кнопку мышку
        (0, operators_1.switchMap)((value) => {
            this.canvasCircle.classList.add('is-active');
            var o = this.canvas;
            let offsetLeft = 0;
            let offsetTop = 0;
            do {
                offsetLeft += o.offsetLeft;
                offsetTop += o.offsetTop;
                //@ts-ignore
            } while (o = o.offsetParent);
            return this.mouseMove$.pipe((0, operators_1.startWith)(value), (0, operators_1.map)((e) => {
                return [
                    Math.max(0, Math.min(e.pageX - offsetLeft, this.canvas.width)),
                    Math.max(0, Math.min(e.pageY - offsetTop, this.canvas.height)),
                ];
            }), (0, operators_1.takeUntil)(this.mouseUp$.pipe((0, operators_1.tap)((e) => {
                this.canvasCircle.classList.remove('is-active');
            }))));
        }), (0, operators_1.distinctUntilChanged)((prev, curr) => {
            return (prev[0] == curr[0]) && (prev[1] == curr[1]);
        })).subscribe((value) => {
            const imagesDataPixelCursor = this.ctx.getImageData(value[0], value[1], 1, 1);
            if (!imagesDataPixelCursor.data[3] && !imagesDataPixelCursor.data[3] && !imagesDataPixelCursor.data[3] && !imagesDataPixelCursor.data[3]) {
            }
            else {
                let imageData = this.ctx.getImageData(value[0] - 6, value[1] - 6, 13, 13);
                this.ctxPreview.clearRect(0, 0, this.canvasCirclePreview.width, this.canvasCirclePreview.height);
                this.ctxPreview.putImageData(imageData, 0, 0);
                const color = new tinycolor_1.TinyColor({
                    r: imagesDataPixelCursor.data[0],
                    g: imagesDataPixelCursor.data[1],
                    b: imagesDataPixelCursor.data[2],
                    a: imagesDataPixelCursor.data[3] / 255
                });
                this.$scope.$apply(() => {
                    this.data.imagePickerPaletteIndex = Math.min(this.data.imagePickerPaletteIndex || 0, this.palette.length - 1);
                    this.palette[this.data.imagePickerPaletteIndex] = {
                        canvasCirclePoint: [`${value[0]}px`, `${value[1]}px`,],
                        color: color,
                        ntc: (0, ntc_ts_1.getColorName)(color.toString('hex')),
                        shades: []
                    };
                });
            }
        });
        (0, rxjs_1.fromEvent)(document, 'keydown').pipe((0, operators_1.filter)((event) => {
            return (event.keyCode == 32) || (event.keyCode == 13);
        }), (0, operators_1.filter)(() => {
            return ((this.kind == 'image') && this.data.image) || (this.kind == 'palette');
        }), (0, operators_1.tap)(() => {
            this.$scope.$apply(() => {
                this.generate();
            });
        })).subscribe();
    }
    setKind(kind) {
        if ((this.kind == 'palette') && (kind == 'image')) {
            this.kind = kind;
            if (!this.data.image) {
                //@ts-ignore
                delete this.data.imagePickerPaletteIndex;
                this.$palette.next([]);
                this.palette = [];
            }
        }
        else {
            this.kind = kind;
            if (!this.palette.length)
                this.generate();
        }
    }
    generate() {
        if (this.kind == 'image') {
            if (this.data.image) {
                //@ts-ignore
                this.data.imagePickerPaletteIndex = undefined;
                const imagePalette = (0, palette_from_image_1.paletteFromImage)(this.data.image, {
                    colorCount: 10,
                    strategy: "kmeans",
                    pixelRatio: 0.008,
                });
                if (imagePalette) {
                    this.$palette.next([
                        ...this.palette.map((item) => item.color),
                        ...imagePalette.colors.splice(this.palette.length, this.data.paleteColors - this.palette.length).map((item) => new tinycolor_1.TinyColor(item.rgba))
                    ]
                    // imagePalette?.colors.map((item: any) => new TinyColor(item.rgba))
                    );
                }
            }
        }
        else {
            this.data.image = undefined;
            this.$palette.next(this.randomColor().polyad(this.palette.length || defaultColors));
        }
    }
    undo() {
        this.historyIndex = Math.min(this.history.length - 1, this.historyIndex + 1);
        this.$palette.next(undefined);
    }
    redo() {
        this.historyIndex = Math.max(0, this.historyIndex - 1);
        this.$palette.next(undefined);
    }
    randomColor() {
        return (0, tinycolor_1.random)({ luminosity: 'bright' });
    }
    addToHistory(palette, fromHistory = false) {
        this.hasGen = !palette.every((item) => item.lock);
        if (!fromHistory) {
            this.history = [
                palette,
                ...this.history.splice(this.historyIndex, this.history.length)
            ];
            this.historyIndex = 0;
        }
    }
    actionItem($event, action, index, shadeIndex) {
        $event.preventDefault();
        $event.stopPropagation();
        switch (action) {
            case 'remove': {
                if (this.palette.length > 2) {
                    this.data.paleteColors = Math.max(2, this.data.paleteColors - 1);
                    this.palette.splice(index, 1);
                    if (this.data.imagePickerPaletteIndex == index) {
                        this.data.imagePickerPaletteIndex = 0;
                    }
                }
                break;
            }
            case 'addLeft': {
                if (this.palette.length < 10) {
                    let color;
                    if ((index - 1) < 0) {
                        color = this.palette[index].color.spin(10);
                    }
                    else {
                        color = this.palette[index].color.mix(this.palette[index - 1].color);
                    }
                    this.palette = [
                        ...this.palette.slice(0, index),
                        {
                            color: color,
                            ntc: (0, ntc_ts_1.getColorName)(color.toString('hex')),
                            shades: []
                        },
                        ...this.palette.slice(index)
                    ];
                }
                break;
            }
            case 'addRight': {
                if (this.palette.length < 10) {
                    if (this.kind == 'image') {
                        //@ts-ignore
                        this.data.paleteColors = Math.min(10, this.data.paleteColors + 1);
                        this.generate();
                    }
                    else {
                        const color = this.palette[index].color.spin(-10);
                        this.palette = [
                            ...this.palette.slice(0, index + 1),
                            {
                                color: color,
                                ntc: (0, ntc_ts_1.getColorName)(color.toString('hex')),
                                shades: []
                            },
                            ...this.palette.slice(index + 1)
                        ];
                    }
                }
                break;
            }
            case 'lock': {
                this.palette[index] = ng.copy(this.palette[index]);
                this.palette[index].lock = !this.palette[index].lock;
                break;
            }
            case 'shades': {
                if (this.palette[index].shades.length == 0) {
                    let color = this.palette[index].originalColor || this.palette[index].color;
                    this.palette[index].shades = this.getShades(color);
                }
                break;
            }
            case 'random': {
                const color = this.randomColor();
                this.palette[index] = {
                    color: color,
                    ntc: (0, ntc_ts_1.getColorName)(color.toString('hex')),
                    lock: this.palette[index].lock,
                    shades: []
                };
                break;
            }
            case 'select': {
                if (shadeIndex != undefined) {
                    this.palette[index].originalColor = this.palette[index].originalColor || this.palette[index].color;
                    this.palette[index].color = this.palette[index].shades[shadeIndex];
                    this.palette[index].ntc = (0, ntc_ts_1.getColorName)(this.palette[index].color.toString('hex'));
                    this.palette[index].shades = [];
                }
                break;
            }
            case 'moveLeft': {
                [this.palette[index], this.palette[index - 1]] = [this.palette[index - 1], this.palette[index]];
                if (this.data.imagePickerPaletteIndex == index) {
                    this.data.imagePickerPaletteIndex = index - 1;
                }
                else if (this.data.imagePickerPaletteIndex == (index - 1)) {
                    this.data.imagePickerPaletteIndex = index;
                }
                break;
            }
            case 'moveRight': {
                [this.palette[index], this.palette[index + 1]] = [this.palette[index + 1], this.palette[index]];
                if (this.data.imagePickerPaletteIndex == index) {
                    this.data.imagePickerPaletteIndex = index + 1;
                }
                else if (this.data.imagePickerPaletteIndex == (index + 1)) {
                    this.data.imagePickerPaletteIndex = index;
                }
                break;
            }
            case 'selectColor': {
                this.data.imagePickerPaletteIndex = index;
                if (!this.palette[this.data.imagePickerPaletteIndex].canvasCirclePoint) {
                    const pos = this.getColorPos(this.palette[this.data.imagePickerPaletteIndex].color);
                    if (pos) {
                        this.palette[this.data.imagePickerPaletteIndex].canvasCirclePoint = [`${pos[0]}px`, `${pos[1]}px`];
                    }
                }
            }
        }
        this.addToHistory(ng.copy(this.palette));
    }
    uploadImage() {
        this.actionService.uploadFactory('image/*').then((value) => {
            this.drawImage(value);
            // image.src = d.default
        }, (err) => {
            if (err)
                alert(err);
        });
    }
    downloadImage() {
        const paletteSVG = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        paletteSVG.setAttribute('viewBox', `0 0 ${100 * this.palette.length}, 220`);
        paletteSVG.appendChild(new Comment(" Downloaded from palette.zone - https://palette.zone "));
        paletteSVG.appendChild(new Comment(" Author is Ivan Fedoseev "));
        this.palette.forEach((item, index) => {
            const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            rect.setAttribute('fill', item.color.toString('hex'));
            rect.setAttribute('x', (100 * index).toString());
            rect.setAttribute('y', '0');
            rect.setAttribute('width', '100');
            rect.setAttribute('height', '220');
            paletteSVG.appendChild(rect);
        });
        this.palette.forEach((item, index) => {
            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            //text.setAttribute('style', `font-size:14px;font-family:sans-serif;fill:${item.color.isDark() ? '#fff' : '#000'}`)
            text.setAttribute('style', `font-size:14px;font-family:'Open Sans', sans-serif;fill:${item.color.isDark() ? '#fff' : '#000'}`);
            text.setAttribute('x', (14 + 100 * index).toString());
            text.setAttribute('y', '200');
            text.textContent = item.color.toString('hex').toUpperCase();
            paletteSVG.appendChild(text);
        });
        this.actionService.downloadFactory('palette_zone', (0, utils_1.dataURLtoBlob)(`data:image/svg+xml;charset=utf-8,${window.btoa(new XMLSerializer().serializeToString(paletteSVG))}`));
    }
    drawImage(dataUri) {
        const image = new Image();
        this.$timeout(() => {
            //@ts-ignore
            this.data.image = image;
        });
        image.onload = () => {
            (0, utils_1.drawImageScaled)(image, this.ctx);
            this.$scope.$apply(() => {
                this.palette = [];
                this.generate();
            });
        };
        image.src = dataUri;
    }
    getShades(color) {
        return [
            color,
            color.shade(10),
            color.shade(20),
            color.shade(30),
            color.shade(40),
            color.shade(50),
            color.shade(60),
            color.shade(70),
            color.shade(80),
            color.shade(90),
        ];
    }
    getColorPos(color) {
        const imgData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
        const data = imgData.data;
        const rgba = color.toRgb();
        for (let row = 0; row < this.canvas.height; row++) {
            for (let col = 0; col < this.canvas.width; col++) {
                const i = (col + (row * imgData.width)) * 4;
                if (`${data[i]},${data[i + 1]},${data[i + 2]},${(data[i + 3] / 255).toFixed(0)}` == `${rgba.r},${rgba.g},${rgba.b},${rgba.a}`) {
                    return [col, row];
                }
            }
        }
    }
    copy($event, value) {
        $event.preventDefault();
        $event.stopPropagation();
        this.actionService.copyFactory(null, value);
    }
    move($event) {
        console.log($event);
    }
}
PaletteCtrl.$inject = ['$scope', '$timeout', 'ActionService'];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.directive('ngDropFile', directives_1.ngDropFile);
appModule.component('gamePalette', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PaletteCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('palette/');
    }]);
